<template>
  <div class="maincontent bg_darkest text_white" :class="theme">
    <div class="row m-0 h-100">
      <div class="col-12 p-0 h-100">
        <div class="row m-0 h-100" v-if="currentPage!=''">
          <div class="col-12 p-0 abs_top">
            <custom-header :page='currentPage' :subPage='currentSubPage' :selectedMenuItem="selectedMenuItem" v-if='!showSubMenu && selectedMenuItem.title'></custom-header>
          </div>
          <div class="col-12 px-0 px-md-4 px-lg-0 overflow_y page_content mt-4 pb-5" :class="{ 'has_header': !showSubMenu, 'has_subheader': currentSubPage !== '' }">
            <div class="row m-0 h-100">
              <div class="col-12 p-0">
                <trips-page v-if="currentPage === 'trips'" :journeysActivePage='currentSubPage'></trips-page>
                <details-page v-if="currentPage === 'details'" :detailsActivePage='currentSubPage' @changeSubPage="changeSubPage" @changeActivePage="changeActivePage"></details-page>
                <documents-page v-if="currentPage === 'docs'" :documentsActivePage='currentSubPage'></documents-page>
                <planner-page v-if="currentPage === 'planner'"></planner-page>
                <social-media-page v-if="currentPage === 'socialMedia'"></social-media-page>
              </div>
              <div class="col-12 pl-0 pr-4 mt-5 mb-2 align-self-end">
                <div class="row m-0 justify-content-between align-items-center">
                  <back-button v-if="prevPage"  :class="{back_btn_submenu_open : showSubMenu}" @click="goBack"/>
                  <div class="col-6 text-right pr-5" :class="{'offset-6': !prevPage}">
                    <img src="../assets/images/more_logo.png" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 align-items-center h-100 overflow_y" v-else>
          <div class="col-12 p-0 h-100">
            <!--DESKTOP-->
            <div class="row m-0 align-items-center h-100 d-none d-lg-flex">
              <div class="col-12 text-center font1">
                My personal MORE Family<br>
                Collection profile
              </div>
              <div class="abs_bottom col-12 pl-0 pr-4">
                <div class="row m-0 justify-content-between align-items-center">
                  <div class="col text-right pr-5">
                    <img src="../assets/images/more_logo.png" class="img-fluid">
                  </div>
                </div>
              </div>
            </div>
            <!--END DESKTOP-->
            <!--MOBILE-->
            <!-- <div class="row m-0 align-items-center justify-content-center h-100 d-flex d-lg-none">
              <div class="col-11 py-4 px-md-5 px-4">
                <custom-menu @selectMenu="selectMenu"></custom-menu>
              </div>
            </div> -->
            <!--END MOBILE-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainPage',
  props: ['user', 'theme', 'currentPage', 'currentSubPage', 'showSubMenu', 'selectedMenuItem', 'prevPage'],
  components: {
    'back-button': () => import('@/components/BackButton.vue'),
    'custom-header': () => import('@/components/Header.vue'),
    'trips-page': () => import('@/pages/JourneysPage.vue'),
    'documents-page': () => import('@/pages/DocumentsPage.vue'),
    'details-page': () => import('@/pages/DetailsPage.vue'),
    'planner-page': () => import('@/pages/PlannerPage.vue'),
    'social-media-page': () => import('@/pages/SocialMediaPage.vue')
    // 'custom-menu': () => import('@/components/Menu.vue')
  },
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    changeActivePage (val) {
      this.$emit('changeActivePage', val)
    },
    changeSubPage (val) {
      this.$emit('changeSubPage', val)
    }
    // selectMenu (val) {
    //   this.$emit('selectMenu', val)
    // },
    // activeSubMenu (val) {
    //   this.$emit('activeSubMenu', val)
    // }
  }
}
</script>
<style scoped>
.maincontent {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  background-size: cover;
  background-position: center center;
}
.page_content {
  height: 100%;
}
.page_content.has_header {
  padding-top: 55px;
}
.page_content.has_subheader {
  padding-top: 80px;
}
.abs_top {
  position: absolute;
  top: 0;
  z-index: 10;
}
.abs_bottom {
  position: absolute;
  bottom: 1.5rem;
  z-index: 10;
}
.beach {
  background-image: url(../assets/images/bg_beach.png);
  background-position: 200px bottom !important;
}
.bush {
  background-image: url(../assets/images/bg_bush.png);
  background-position: 300px bottom !important;
}
.city {
  background-image: url(../assets/images/bg_city.png);
  background-position: 300px bottom !important;
}
.mountain {
  background-image: url(../assets/images/bg_mountain.png);
  background-position: 200px bottom !important;
}
@media (min-width: 768px) {
  .page_content.has_header {
    padding-top: 85px;
  }
  .page_content.has_subheader {
    padding-top: 125px;
  }
}
@media (min-width: 992px) {
  .maincontent {
    height: 100vh;
    width: 70vw;
    position: absolute;
    right: 0;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    overflow-y: hidden;
  }
  .page_content {
    height: 100vh;
    overflow-y: auto;
  }
  .page_content.has_header {
    padding-top: 115px;
  }
  .page_content.has_subheader {
    padding-top: 165px;
  }
  .mpt_logo_page {
    width: 165px;
    height: 152px;
  }
  .back_btn {
    width: 20vw;
    cursor: pointer;
    padding-left: 4rem;
  }
  .back_btn_submenu_open {
    padding-left: 26rem;
  }
}
</style>
